import IEntityModel from "../../../ApiModels/Commons/IEntityModel";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const menuFetchByIdCompleteAction = (entityName: string, model: IEntityModel, conditional: any | undefined = undefined) => {
    return createAsyncThunk(
        `${entityName}/fetchById`,
        async (entity_id: number, {rejectWithValue}) => {
            const response = await model.find(entity_id, {
                includes: [
                    'categories.products.modifiers.modifierItems'
                ]
            })
            if(response === undefined){
                return rejectWithValue(undefined)
            }
            return response
        },
        {
            condition(arg, {getState, extra}): boolean {
                return conditional? conditional(arg, getState(), extra) : true;
            }
        }
    )
}