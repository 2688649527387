import {IModifierItemEntity} from "./types";
import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import ModifierItemModel from "../../../ApiModels/ModifierItemModel";
import {menusActions} from "../Menu/MenuRedux";

const initialData: EntityState<IModifierItemEntity> = {
    ids: [],
    entities: {}
}

export const adapter = createEntityAdapter<IModifierItemEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (entity) => entity.id
})

const API_MODEL = new ModifierItemModel()
const ENTITY_NAME = 'modifiers'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL)
const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL)
const fetchPage = entityFetchPageAction(ENTITY_NAME)

const entitiesSlice = createSlice({
    name: ENTITY_NAME,
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        [menusActions.fetchByIdComplete.fulfilled.type]: (state, {payload}) => {
            payload.categories.forEach((menu: any) =>
                menu.products.forEach((product: any) =>
                    product.modifiers.forEach((modifier: any) => {
                        modifier.modifier_items.forEach((modifier_item: any) => {
                            state.entities[modifier_item.id] = {
                                id: modifier_item.id,
                                max: modifier_item.max,
                                min: modifier_item.min,
                                defaultUnits: modifier_item.defaultUnits,
                                modifier_id: modifier_item.modifier_id,
                                product_id: modifier_item.product_id,
                                unitPrice: modifier_item.unitPrice
                            }
                        })
                    })))
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
    }
})

export const modifierItemActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage
}
export default entitiesSlice.reducer