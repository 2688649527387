import {FC} from "react";

const MultiView: FC = (props) => {

    return (
        <div className={'dev-multiview'}>
            <div className={'dev-container'}>
                <div className={'dev-content'} style={{minWidth: '320px'}}>{props.children}</div>
                <div className={'dev-content'} style={{minWidth: '480px'}}>{props.children}</div>
                <div className={'dev-content'} style={{minWidth: '770px'}}>{props.children}</div>
                <div className={'dev-content'} style={{minWidth: '1024px'}}>{props.children}</div>
                <div className={'dev-content'} style={{minWidth: '1200px'}}>{props.children}</div>
            </div>
        </div>
    )
}

export default MultiView