interface IApiStatus {
    ok: boolean, // true if any successful action executed
    code: number, // status code
    apiVersion?: string, // Api version that sent the response
    message?: string // Message from the api
}

interface IInTableApiResponse<T> {
    _data?: T, // Data expected with the response
    _status: IApiStatus, // Status data from the api
}

export function apiFetch<T>(requestInfo: RequestInfo, requestInit: RequestInit = {}): Promise<IInTableApiResponse<T>> {
    let token = window.localStorage.getItem('token')

    if(requestInit.method?.toUpperCase() === 'POST' && requestInit.body){
        requestInit.headers = requestInit.headers?? {};
        // @ts-ignore
        requestInit.headers['Content-Type'] = `application/json`;
    }

    if(token){
        requestInit.headers = requestInit.headers?? {};
        // @ts-ignore
        requestInit.headers['Authorization'] = `Bearer ${token}`;
    }
    return fetch(requestInfo, requestInit)
        .then(res => res.json())
        .catch((err): IInTableApiResponse<T> => {
            console.error(err)
            return {
                _status: {
                    ok: false,
                    code: 0,
                    apiVersion: '',
                    message: 'Request Failed'
                }
            }
        })
}