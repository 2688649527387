import {IBusinessEntity} from "./types";
import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import BusinessModel from "../../../ApiModels/BusinessModel";
import {businessFetchByIdDataAction} from "./actions";

const initialData: EntityState<IBusinessEntity> = {
    ids: [],
    entities: {}
}

export const adapter = createEntityAdapter<IBusinessEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (business) => business.id
})

const API_MODEL = new BusinessModel()
const ENTITY_NAME = 'business'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL)
export const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL, {
    includes: [
        'address'
    ]
})
const fetchPage = entityFetchPageAction(ENTITY_NAME)
export const fetchByIdData = businessFetchByIdDataAction(ENTITY_NAME, API_MODEL)

const entitiesSlice = createSlice({
    name: ENTITY_NAME,
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchById.fulfilled.type]: (state, {payload}) => {
            state.entities[payload.id] = {
                id: payload.id,
                name: payload.name,
                address_id: payload.address_id,
                description: payload.description,
                cover_file_url: payload.cover_file_url,
                currency_id: payload.currency_id,
                menu_id: payload.menu_id,
                public: !!payload.public,

                contact_ids: payload.contacts_ids,
                paymentMethod_ids: payload.paymentMethods_ids,
                schedule: payload.schedule,
            }
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
        [fetchManyById.fulfilled.type]: (state, {payload}) => {
            payload.resources.forEach((entity: any) => {
                state.entities[entity.id] = {
                    id: entity.id,
                    name: entity.name,
                    address_id: entity.address_id,
                    description: entity.description,
                    cover_file_url: entity.cover_file_url,
                    currency_id: entity.currency_id,
                    menu_id: entity.menu_id,
                    public: !!entity.public,

                    contact_ids: entity.contacts_ids,
                    paymentMethod_ids: entity.paymentMethods_ids,
                    schedule: entity.schedule,
                }
                state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
            })
        },
        [fetchByIdData.fulfilled.type]: (state, {payload}) => {
            state.entities[payload.id] = {
                id: payload.id,
                name: payload.name,
                address_id: payload.address_id,
                description: payload.description,
                cover_file_url: payload.cover_file_url,
                currency_id: payload.currency_id,
                menu_id: payload.menu_id,
                public: !!payload.public,

                contact_ids: payload.contacts_ids,
                paymentMethod_ids: payload.paymentMethods_ids,
                schedule: payload.schedule,
            }
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
        [fetchPage.fulfilled.type]: (state, {payload}) => {
            payload.resources.forEach((entity: any) => {
                state.entities[entity.id] = {
                    id: entity.id,
                    name: entity.name,
                    address_id: entity.address_id,
                    description: entity.description,
                    cover_file_url: entity.cover_file_url,
                    currency_id: entity.currency_id,
                    menu_id: entity.menu_id,
                    public: !!entity.public,

                    contact_ids: entity.contacts_ids,
                    paymentMethod_ids: entity.paymentMethods_ids,
                    schedule: entity.schedule,
                }
                state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
            })
        },
    }
})

export const businessActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage,
    fetchByIdData,
}
export default entitiesSlice.reducer
