import {IAddressEntity} from "./types";
import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import AddressModel from "../../../ApiModels/AddressModel";
import {fetchByIdData as businessFetchByIdData, fetchManyById as businessFetchManyById} from "../Business/BusinessRedux";

const initialData: EntityState<IAddressEntity> = {
    ids: [],
    entities: {}
}

export const adapter = createEntityAdapter<IAddressEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (entity) => entity.id
})

const API_MODEL = new AddressModel()
const ENTITY_NAME = 'address'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL)
const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL)
const fetchPage = entityFetchPageAction(ENTITY_NAME)

const entitiesSlice = createSlice({
    name: 'address',
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        [businessFetchByIdData.fulfilled.type]: (state, {payload}) => {
            let address = payload.address;
            state.entities[address.id] = {
                id: address.id,
                address1: address.address1,
                address2: address.address2,
                city: address.city,
                region: address.region,
                country: address.country,
                postalCode: address.postalCode,
                user_id: address.user_id,
                user_model: address.user_model,
                longitude: address.longitude,
                latitude: address.latitude,
            }
        },
        [businessFetchManyById.fulfilled.type]: (state, {payload}) => {
            payload.resources.forEach((entity: any) => {
                let address = entity.address
                state.entities[address.id] = {
                    id: address.id,
                    address1: address.address1,
                    address2: address.address2,
                    city: address.city,
                    region: address.region,
                    country: address.country,
                    postalCode: address.postalCode,
                    user_id: address.user_id,
                    user_model: address.user_model,
                    longitude: address.longitude,
                    latitude: address.latitude,
                }
            })
        },
    }
})

export const addressActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage
}
export default entitiesSlice.reducer