import {IProductEntity} from "./types";
import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import ProductModel from "../../../ApiModels/ProductModel";
import {RootState} from "../../store";
import {menusActions} from "../Menu/MenuRedux";

const initialData: EntityState<IProductEntity> = {
    ids: [],
    entities: {}
}

export const adapter = createEntityAdapter<IProductEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (entity) => entity.id
})

const API_MODEL = new ProductModel()
const ENTITY_NAME = 'product'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL, {}, (args: number, state: RootState) => {
    return !state.data.products.entities[args]
})
const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL)
const fetchPage = entityFetchPageAction(ENTITY_NAME)

const entitiesSlice = createSlice({
    name: ENTITY_NAME,
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        [fetchById.fulfilled.type]: (state, {payload}) => {
            state.entities[payload.id] = {
                id: payload.id,
                business_id: payload.business_id,
                name: payload.name,
                cover_file_url: payload.cover_file_url,
                description: payload.description,
                price: payload.price,
                modifier_ids: payload.modifiers_ids,
                available: payload.available,
            }
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
        [fetchManyById.fulfilled.type]: (state, {payload}) => {
            console.log(state, payload)
            payload.resources.forEach((entity: any) => {
                state.entities[entity.id] = {
                    id: payload.id,
                    business_id: payload.business_id,
                    name: payload.name,
                    cover_file_url: payload.cover_file_url,
                    description: payload.description,
                    price: payload.price,
                    available: payload.available,

                    modifier_ids: payload.modifiers_ids,
                }
            })
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
        [menusActions.fetchByIdComplete.fulfilled.type]: (state, {payload}) => {
            payload.categories.forEach((menu: any) =>
                menu.products.forEach((product: any) => {
                    state.entities[product.id] = {
                        id: product.id,
                        business_id: product.business_id,
                        name: product.name,
                        cover_file_url: product.cover_file_url,
                        description: product.description,
                        price: product.price,
                        available: product.available,

                        modifier_ids: product.modifiers_ids,
                    }
                }))
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
    }
})

export const productsActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage
}
export default entitiesSlice.reducer
