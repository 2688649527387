import {IAuthEntity} from "./types";
import {createSlice} from "@reduxjs/toolkit";
import actions from "./actions";

let ls = localStorage.getItem('redux-auth')

const initialData: IAuthEntity = ls ? JSON.parse(ls) : {
    loggedIn: false,
    user: undefined,
    actionState: {
        login: {
            status: "idle",
            error: null
        },
        logout: {
            status: "idle",
            error: null
        },
        register: {
            status: "idle",
            error: null
        },
        exists: {
            status: "idle",
            error: null,
            exists: false
        }
    }
}

const slice = createSlice({
    name: 'auth',
    initialState: initialData,
    reducers: {
        clearActionState: actions.clearActionState
    },
    extraReducers: {
        [actions.fetchLogin.fulfilled.type]: (state, {payload}) => {
            state.actionState.login = {
                status: 'succeeded',
                error: null
            }
            state.user = {
                id: payload._data.id,
                name: payload._data.name,
                email: payload._data.email,
                access_token: payload._data.access_token,
                token_type: payload._data.token_type,
                expires_at: payload._data.expires_at,
                email_verified_at: payload._data.email_verified_at,
                admin: false
            }
        },
        [actions.fetchLogin.pending.type]: (state, {payload}) => {
            state.actionState.login = {
                status: 'loading',
                error: null
            }
        },
        [actions.fetchLogin.rejected.type]: (state, {payload}) => {
            state.actionState.login = {
                status: 'failed',
                error: null
            }
        },

        [actions.fetchLogout.fulfilled.type]: (state, {payload}) => {
            state.actionState.logout = {
                status: 'succeeded',
                error: null
            }
            state.user = null
        },
        [actions.fetchLogout.pending.type]: (state, {payload}) => {
            state.actionState.logout = {
                status: 'loading',
                error: null
            }
        },
        [actions.fetchLogout.rejected.type]: (state, {payload}) => {
            state.actionState.logout = {
                status: 'failed',
                error: null
            }
        },

        [actions.fetchRegister.fulfilled.type]: (state, {payload}) => {
            state.actionState.register = {
                status: 'succeeded',
                error: null
            }
        },
        [actions.fetchRegister.pending.type]: (state, {payload}) => {
            state.actionState.register = {
                status: 'loading',
                error: null
            }
        },
        [actions.fetchRegister.rejected.type]: (state, {payload}) => {
            state.actionState.register = {
                status: 'failed',
                error: null
            }
        },

        [actions.fetchExists.fulfilled.type]: (state, {payload}) => {
            state.actionState.exists = {
                status: "succeeded",
                error: null,
                exists: payload._data.exists
            }
        },
        [actions.fetchExists.pending.type]: (state, {payload}) => {
            state.actionState.exists = {
                status: "loading",
                error: null,
                exists: false
            }
        },
        [actions.fetchExists.rejected.type]: (state, {payload}) => {
            state.actionState.exists = {
                status: "failed",
                error: null,
                exists: false
            }
        },




        [actions.fetchAdminLogin.fulfilled.type]: (state, {payload}) => {
            state.actionState.login = {
                status: 'succeeded',
                error: null
            }
            state.user = {
                id: payload._data.id,
                name: payload._data.name,
                email: payload._data.email,
                access_token: payload._data.access_token,
                token_type: payload._data.token_type,
                expires_at: payload._data.expires_at,
                email_verified_at: payload._data.email_verified_at,
                admin: true
            }
        },
        [actions.fetchAdminLogin.pending.type]: (state, {payload}) => {
            state.actionState.login = {
                status: 'loading',
                error: null
            }
        },
        [actions.fetchAdminLogin.rejected.type]: (state, {payload}) => {
            state.actionState.login = {
                status: 'failed',
                error: null
            }
        },

        [actions.fetchAdminLogout.fulfilled.type]: (state, {payload}) => {
            state.actionState.logout = {
                status: 'succeeded',
                error: null
            }
            state.user = null
        },
        [actions.fetchAdminLogout.pending.type]: (state, {payload}) => {
            state.actionState.logout = {
                status: 'loading',
                error: null
            }
        },
        [actions.fetchAdminLogout.rejected.type]: (state, {payload}) => {
            state.actionState.logout = {
                status: 'failed',
                error: null
            }
        },

        [actions.fetchAdminRegister.fulfilled.type]: (state, {payload}) => {
            state.actionState.register = {
                status: 'succeeded',
                error: null
            }
        },
        [actions.fetchAdminRegister.pending.type]: (state, {payload}) => {
            state.actionState.register = {
                status: 'loading',
                error: null
            }
        },
        [actions.fetchAdminRegister.rejected.type]: (state, {payload}) => {
            state.actionState.register = {
                status: 'failed',
                error: null
            }
        },

        [actions.fetchAdminExists.fulfilled.type]: (state, {payload}) => {
            state.actionState.exists = {
                status: "succeeded",
                error: null,
                exists: payload._data.exists
            }
        },
        [actions.fetchAdminExists.pending.type]: (state, {payload}) => {
            state.actionState.exists = {
                status: "loading",
                error: null,
                exists: false
            }
        },
        [actions.fetchAdminExists.rejected.type]: (state, {payload}) => {
            state.actionState.exists = {
                status: "failed",
                error: null,
                exists: false
            }
        },
    }
})

export const authActions = {
    ...actions,
    ...slice.actions,
}
export default slice.reducer;