import {IMenuEntity} from "./types";
import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import MenuModel from "../../../ApiModels/MenuModel";
import {menuFetchByIdCompleteAction} from "./actions";

const initialData: EntityState<IMenuEntity> = {
    ids: [],
    entities: {

    }
}

export const adapter = createEntityAdapter<IMenuEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (entity) => entity.id
})

const API_MODEL = new MenuModel()
const ENTITY_NAME = 'menu'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL)
const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL)
const fetchPage = entityFetchPageAction(ENTITY_NAME)
const fetchByIdComplete = menuFetchByIdCompleteAction(ENTITY_NAME, API_MODEL)

const entitiesSlice = createSlice({
    name: ENTITY_NAME,
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        [fetchById.fulfilled.type]: (state, {payload}) => {
            state.entities[payload.id] = {
                id: payload.id,
                business_id: payload.business_id,
                categories_ids: payload.categories_ids,
                category_order: payload.category_order,
            }
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
        [fetchByIdComplete.fulfilled.type]: (state, {payload}) => {
            state.entities[payload.id] = {
                id: payload.id,
                business_id: payload.business_id,
                categories_ids: payload.categories_ids,
                category_order: payload.category_order,
            }
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
    }
})

export const menusActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage,
    fetchByIdComplete
}
export default entitiesSlice.reducer