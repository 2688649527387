import {ICurrentAddressState} from "./currentAddressRedux";
import {IBusinessEntity} from "./Entities/Business/types";
import businessReducer from "./Entities/Business/BusinessRedux";
import {ICategoryEntity} from "./Entities/Category/types";
import categoryReducer from "./Entities/Category/CategoryRedux";
import {IProductEntity} from "./Entities/Product/types";
import productReducer from "./Entities/Product/ProductRedux";
import {IModifierEntity} from "./Entities/Modifier/types";
import modifierReducer from "./Entities/Modifier/ModifierRedux";
import {IAuthEntity} from "./Auth/types";
import authReducer from "./Auth/AuthRedux";
import {IPendingProductEntity} from "./Cart/PendingProduct/types";
import pendingProductReducer from "./Cart/PendingProduct/PendingProductRedux";
import menuReducer from "./Entities/Menu/MenuRedux";
import {IMenuEntity} from "./Entities/Menu/types";
import {ICurrencyEntity} from "./Entities/Currency/types";
import currencyReducer from "./Entities/Currency/CurrencyRedux";
import BusinessContactRedux from "./Entities/BusinessContact/BusinessContactRedux";
import paymentMethodReducer from "./Entities/PaymentMethods/PaymentMethodRedux";
import addressReducer from "./Entities/Address/AddressRedux";
import {ICurrentsEntity} from "./Currents/types";
import currentsReducer from "./Currents/CurrentsRedux";
import cartReducer from "./Cart/CartRedux";
import {ICartEntity} from "./Cart/type";
import {combineReducers, configureStore, EntityState, getDefaultMiddleware} from "@reduxjs/toolkit";
import {baseState} from "./preloadedState";
import {IModifierItemEntity} from "./Entities/ModifierItem/types";
import ModifierItemRedux from "./Entities/ModifierItem/ModifierItemRedux";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {saveAuthState, saveEntitiesState} from "./localStorage";
import {IPaymentMethodEntity} from "./Entities/PaymentMethods/types";
import {IBusinessContactEntity} from "./Entities/BusinessContact/types";

export interface IStore {
    currents: ICurrentsEntity,
    auth: IAuthEntity,
    currentAddress: ICurrentAddressState
    cart: {
        cartPending: IPendingProductEntity,
        cart: ICartEntity,
    }
    data: {
        businesses: EntityState<IBusinessEntity>
        categories: EntityState<ICategoryEntity>
        products: EntityState<IProductEntity>
        modifiers: EntityState<IModifierEntity>
        modifierProducts: EntityState<IModifierItemEntity>
        menus: EntityState<IMenuEntity>
        currencies: EntityState<ICurrencyEntity>
        paymentMethods: EntityState<IPaymentMethodEntity>
        businessContact: EntityState<IBusinessContactEntity>
    }
}

const dataReducers = combineReducers({
    businesses: businessReducer,
    categories: categoryReducer,
    products: productReducer,
    modifiers: modifierReducer,
    modifierProducts: ModifierItemRedux,
    menus: menuReducer,
    currencies: currencyReducer,
    addresses: addressReducer,
    paymentMethod: paymentMethodReducer,
    businessContact: BusinessContactRedux
})

const cartReducers = combineReducers({
    cartPending: pendingProductReducer,
    cart: cartReducer
})

const store = configureStore({
    reducer: {
        currents: currentsReducer,
        auth: authReducer,
        cart: cartReducers,
        data: dataReducers
    },
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: baseState,
    middleware: [
        ...getDefaultMiddleware()
    ]
})

store.subscribe(() => {
    saveEntitiesState(store.getState());
    saveAuthState(store.getState());
});


export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
// TODO FIX ANY TYPE BELOW
export const useAppDispatch = () => useDispatch<AppDispatch | any>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector