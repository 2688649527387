import {IModifierEntity} from "./types";
import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import ModifierModel from "../../../ApiModels/ModifierModel";
import {menusActions} from "../Menu/MenuRedux";

const initialData: EntityState<IModifierEntity> = {
    ids: [],
    entities: {}
}

export const adapter = createEntityAdapter<IModifierEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (entity) => entity.id
})

const API_MODEL = new ModifierModel()
const ENTITY_NAME = 'modifier'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL)
const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL)
const fetchPage = entityFetchPageAction(ENTITY_NAME)

const entitiesSlice = createSlice({
    name: ENTITY_NAME,
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        [menusActions.fetchByIdComplete.fulfilled.type]: (state, {payload}) => {
            payload.categories.forEach((menu: any) =>
                menu.products.forEach((product: any) =>
                    product.modifiers.forEach((modifier: any) => {
                        state.entities[modifier.id] = {
                            id: modifier.id,
                            name: modifier.name,
                            type: modifier.type,
                            max: modifier.max,
                            min: modifier.min,
                            notes: modifier.notes,
                            businessId: modifier.businessId
                        }
                    })))
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
    }
})

export const modifierActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage
}
export default entitiesSlice.reducer