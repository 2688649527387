import IEntityModel, {IOptions} from "./Commons/IEntityModel";
import {apiFetch} from "../services/common/intableFetchUtils";

const url = process.env['REACT_APP_API_BASE_URL']

class AddressModel implements IEntityModel {

    modelPath = 'address';

    async find(id: number) {
        let response = await apiFetch(`${url}/api/app/${this.modelPath}/${id}`);
        return response._status.ok ? response._data : undefined
    }

    async findMany(ids: number[], options: IOptions = {}) {
        const urlApi = new URL(`${url}/api/app/${this.modelPath}`);
        urlApi.searchParams.append("per_page", "100");
        urlApi.searchParams.append("filter[id]", ids.join(','));
        if(options.includes){
            urlApi.searchParams.append("include", options.includes.join(','));
        }

        let response = await apiFetch(urlApi.toString());
        return response._status.ok ? response._data : undefined
    }


}

export default AddressModel