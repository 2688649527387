import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPendingModifierItem, IPendingProductEntity} from "./types";

const initialData: IPendingProductEntity = {
    product: 0,
    quantity: 1,
    modifiers: []
}

const slice = createSlice({
    name: 'pendingCart',
    initialState: initialData,
    reducers: {
        clear: _ => initialData,
        updateProduct: (state, {payload}: PayloadAction<IPendingProductEntity>) => ({
            ...payload
        }),
        updateQuantity: (state, {payload}: PayloadAction<number>) => ({
            ...state,
            quantity: payload
        }),
        updateModifier: (state: IPendingProductEntity, {payload}: PayloadAction<IPendingModifierItem>) => {
            const index = state.modifiers.findIndex(x => x.modifierItem_id === payload.modifierItem_id)
            if(index >= 0){
                state.modifiers[index] = payload
            }else{
                state.modifiers.push(payload)
            }
            return state;
        }
    }
})

export const pendingProductActions = slice.actions
export default slice.reducer
