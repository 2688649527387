import {ICartEntity} from "./type";
import {IPendingProductEntity} from "./PendingProduct/types";
import {RootState} from "../store";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialData: ICartEntity = {
    business: null,
    products: []
}

const slice = createSlice({
    name: 'cart',
    initialState: initialData,
    reducers: {
        clear: _ => initialData,
        setBusiness: (state, action: PayloadAction<number>) => {
            state.business = action.payload
            return state
        },
        addProduct: (state, {payload}: PayloadAction<IPendingProductEntity>) => {
            state.products.push({
                product: payload.product,
                quantity: payload.quantity,
                modifiers: payload.modifiers
            })
            return state
        },
        updateProduct: (state, {payload}: PayloadAction<{
            index: number,
            product: IPendingProductEntity,
        }>) => {
            state.products[payload.index] = {
                product: payload.product.product,
                quantity: payload.product.quantity,
                modifiers: payload.product.modifiers
            }
            return state
        },
        removeProduct: (state, {payload}: PayloadAction<number>) => {
            delete state.products[payload]
            return state
        }
    }
})

export const cartActions = slice.actions
export const cartSelector = {
    current: (s: RootState) => s.cart.cart
}
export default slice.reducer