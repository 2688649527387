import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import {IBusinessContactEntity} from "./types";
import BusinessContactModel from "../../../ApiModels/BusinessContactModel";
import {fetchByIdData as businessFetchByIdData} from "../Business/BusinessRedux";

const initialData: EntityState<IBusinessContactEntity> = {
    ids: [],
    entities: {}
}

export const adapter = createEntityAdapter<IBusinessContactEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (businessContact) => businessContact.id
})

const API_MODEL = new BusinessContactModel()
const ENTITY_NAME = 'business-contact'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL)
const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL)
const fetchPage = entityFetchPageAction(ENTITY_NAME)

const entitiesSlice = createSlice({
    name: ENTITY_NAME,
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchById.fulfilled.type]: (state, {payload}) => {
            state.entities[payload.id] = {
                id: payload.id,
                business_id: payload.business_id,
                name: payload.name,
                value: payload.value,
                type: payload.type
            }
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
        [fetchManyById.fulfilled.type]: (state, {payload}) => {
            payload.resources.forEach((entity: any) => {
                state.entities[entity.id] = {
                    id: payload.id,
                    business_id: payload.business_id,
                    name: payload.name,
                    value: payload.value,
                    type: payload.type
                }
            })
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
        [businessFetchByIdData.fulfilled.type]: (state, {payload}) => {
            payload.contacts.forEach((businessContact: any) => {
                state.entities[businessContact.id] = {
                    id: businessContact.id,
                    business_id: businessContact.business_id,
                    name: businessContact.name,
                    value: businessContact.value,
                    type: businessContact.type
                }
            })
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)

        }
    }
})

export const businessContactActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage
}
export default entitiesSlice.reducer