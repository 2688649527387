import {loadAuthState, loadEntitiesState} from "./localStorage";

const persistedState = loadEntitiesState();
const authState = loadAuthState();

let state = {
    data: persistedState
}

if (authState) {
    // @ts-ignore
    state.auth = authState
}

export const baseState = state