import {createAsyncThunk} from "@reduxjs/toolkit";
import {apiFetch} from "../../services/common/intableFetchUtils";
import IEntityModel, {IOptions} from "../../ApiModels/Commons/IEntityModel";

const url = process.env['REACT_APP_API_BASE_URL']

export const entityFetchByIdAction = (entityName: string, model: IEntityModel, options: IOptions = {}, conditional: any | undefined = undefined) => {
    return createAsyncThunk(
        `${entityName}/fetchById`,
        async (entity_id: number, {rejectWithValue}) => {
            const response = await model.find(entity_id, options)
            if (response === undefined) {
                return rejectWithValue(undefined)
            }
            return response
        },
        {
            condition(arg, {getState, extra}): boolean {
                return conditional ? conditional(arg, getState(), extra) : true;
            }
        }
    )
}

export const entityFetchManyByIdAction = (entityName: string, model: IEntityModel, options: IOptions = {}, conditional: any | undefined = undefined) => {
    return createAsyncThunk(
        `${entityName}/fetchManyById`,
        async (entity_ids: number[], {rejectWithValue}) => {
            const response = await model.findMany(entity_ids, options)
            if (response === undefined) {
                return rejectWithValue(undefined)
            }
            return response
        },
        {
            condition(arg, {getState, extra}): boolean {
                return conditional ? conditional(arg, getState(), extra) : true;
            }
        }
    )
}

export const entityFetchPageAction = (entityName: string) => {
    return createAsyncThunk(
        `${entityName}/fetchPage`,
        async () => {
            const response = await apiFetch(`${url}/api/app/${entityName}`)
            return response._data
        }
    )
}