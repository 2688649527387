import React, {FC} from 'react';
import styles from './LoadingContent.module.scss'
import Logo1 from "../../assets/logo-1.svg"

const LoadingContent: FC = () => {
    return (
        <>
            <div className={styles.face}>
                <div className={styles.container}>
                    <img
                        src={Logo1}
                        alt={''}/>
                    <span className={styles.loading}/>
                    <div className={styles.caption}>
                        <h2>Preparando tu mesa</h2>
                        <ul>
                            <li>C</li>
                            <li>A</li>
                            <li>R</li>
                            <li>G</li>
                            <li>A</li>
                            <li>N</li>
                            <li>D</li>
                            <li>O</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>

    );
}
export default LoadingContent;
