import {IPaymentMethodEntity} from "./types";
import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import PaymentMethodModel from "../../../ApiModels/PaymentMethodModel";
import {fetchByIdData} from "../Business/BusinessRedux";

const initialData: EntityState<IPaymentMethodEntity> = {
    ids: [],
    entities: {}
}

export const adapter = createEntityAdapter<IPaymentMethodEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (entity) => entity.id
})

const API_MODEL = new PaymentMethodModel()
const ENTITY_NAME = 'payment-method'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL)
const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL)
const fetchPage = entityFetchPageAction(ENTITY_NAME)

const entitiesSlice = createSlice({
    name: ENTITY_NAME,
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        [fetchByIdData.fulfilled.type]: (state, {payload}) => {
            payload.payment_methods.forEach((paymentMethod: any) => {
                state.entities[paymentMethod.id] = {
                    id: paymentMethod.id,
                    name: paymentMethod.name,
                }
            })
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        }
    }
})

export const paymentMethodActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage,
}
export default entitiesSlice.reducer