import {ICurrentsEntity} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStore} from "../store";


const initialState: ICurrentsEntity = {
    business: null,
    product: null
}

const currentsSlice = createSlice({
    name: 'currents',
    initialState,
    reducers: {
        updateBusiness: {
            reducer: (state, action: PayloadAction<number | undefined>) => (
                {...state, business: action.payload}
            ),
            prepare: (value?: number) => ({ payload: value }),
        },
        updateProduct: {
            reducer: (state, action: PayloadAction<number | undefined>) => (
                {...state, product: action.payload}
            ),
            prepare: (value?: number) => ({ payload: value }),
        },
    }
})

export const selectorCurrentBusiness = (state:IStore) => state.currents.business
export const selectorCurrentProduct = (state:IStore) => state.currents.product

export const { updateBusiness, updateProduct } = currentsSlice.actions
export default currentsSlice.reducer