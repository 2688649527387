import {EntityAdapter} from "@reduxjs/toolkit";

export const storeEntity = <T>(store: T[], payload: any) => {
    if (typeof payload === "undefined") {
        return [...store]
    }
    store = updateStore(store, payload)
    return [...store]
}


export const storeEntities = <T>(store: T[], payload: any) => {
    if (typeof payload === "undefined") {
        return [...store]
    }
    payload.forEach((p: T) => {
        updateStore(store, p);
    })
    return [...store]
}

const updateStore = <T>(store: T[], entity: T): T[] => {
    // @ts-ignore
    let index = store.findIndex(x => x.id === entity.id)
    if (index >= 0) {
        store[index] = entity
    } else {
        store.push(entity)
    }
    return store;
}

export const getAdapterReducers = <T>(adapter: EntityAdapter<T>) => {
    return {
        addOne: adapter.addOne,
        addMany: adapter.addMany,
        removeOne: adapter.removeOne,
        removeMany: adapter.removeMany,
        removeAll: adapter.removeAll,
        updateOne: adapter.updateOne,
        updateMany: adapter.updateMany,
        setAll: adapter.setAll,
    }
}