import {createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {apiFetch} from "../../services/common/intableFetchUtils";
import {IAuthEntity} from "./types";

const REDUX_NAME = 'auth'
const url = process.env['REACT_APP_API_BASE_URL']

const fetchRegister = createAsyncThunk(
    `${REDUX_NAME}/fetchRegister`,
    async (payload: {
        name: string
        email: string
        password: string,
        password_confirmation: string,
    }, {rejectWithValue}) => {
        let response = await apiFetch<{ message: string }>(`${url}/api/auth/register`, {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        return response._status.ok ? response : rejectWithValue(response)
    }
)

const fetchLogin = createAsyncThunk(
    `${REDUX_NAME}/fetchLogin`,
    async (payload: {
        email: string
        password: string,
    }, {rejectWithValue}) => {
        let response = await apiFetch<{
            access_token: string
            token_type: string,
            expires_at: string,
            id: number,
            name: string,
            email: string,
            email_verified_at: string | null
        }>(`${url}/api/auth/login`, {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        if (response._status.ok && response._data?.access_token) {
            window.localStorage.setItem('token', response._data?.access_token)
            return response
        }
        return rejectWithValue(response)
    }
)

const fetchLogout = createAsyncThunk(
    `${REDUX_NAME}/fetchLogout`,
    async (_, {rejectWithValue}) => {
        let response = await apiFetch<{ message: string }>(`${url}/api/auth/logout`)
        if (response._status.ok) {
            window.localStorage.removeItem('token')
        }
        return response._status.ok ? response : rejectWithValue(response)
    }
)

const fetchExists = createAsyncThunk(
    `${REDUX_NAME}/fetchExists`,
    async (user: string, {rejectWithValue}) => {
        let response = await apiFetch<{ exists: boolean }>(`${url}/api/auth/exists`, {
            method: 'POST',
            body: JSON.stringify({user: user})
        })
        return response._status.ok ? response : rejectWithValue(response)
    }
)

const fetchAdminRegister = createAsyncThunk(
    `${REDUX_NAME}/fetchAdminRegister`,
    async (payload: {
        first_name: string
        last_name: string
        email: string
        password: string,
        password_confirmation: string,
    }, {rejectWithValue}) => {
        let response = await apiFetch<{ message: string }>(`${url}/api/admin/auth/register`, {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        return response._status.ok ? response : rejectWithValue(response)
    }
)

const fetchAdminLogin = createAsyncThunk(
    `${REDUX_NAME}/fetchAdminLogin`,
    async (payload: {
        email: string
        password: string,
    }, {rejectWithValue}) => {
        let response = await apiFetch<{
            access_token: string
            token_type: string,
            expires_at: string,
            id: number,
            name: string,
            email: string,
            email_verified_at: string | null
        }>(`${url}/api/admin/auth/login`, {
            method: 'POST',
            body: JSON.stringify(payload)
        })
        if (response._status.ok && response._data?.access_token) {
            window.localStorage.setItem('token', response._data?.access_token)
            return response
        }
        return rejectWithValue(response)
    }
)

const fetchAdminLogout = createAsyncThunk(
    `${REDUX_NAME}/fetchAdminLogout`,
    async (_, {rejectWithValue}) => {
        let response = await apiFetch<{ message: string }>(`${url}/api/admin/auth/logout`)
        if (response._status.ok || response._status.code === 401) {
            window.localStorage.removeItem('token')
            return response
        }
        return rejectWithValue(response)
    }
)

const fetchAdminExists = createAsyncThunk(
    `${REDUX_NAME}/fetchAdminExists`,
    async (user: string, {rejectWithValue}) => {
        let response = await apiFetch<{ exists: boolean }>(`${url}/api/admin/auth/exists`, {
            method: 'POST',
            body: JSON.stringify({user: user})
        })
        return response._status.ok ? response : rejectWithValue(response)
    }
)

const clearActionState = (state: IAuthEntity, action: PayloadAction<void>) => {
    state.actionState = {
        login: {
            status: "idle",
            error: null
        },
        logout: {
            status: "idle",
            error: null
        },
        register: {
            status: "idle",
            error: null
        },
        exists: {
            status: "idle",
            error: null,
            exists: false
        }
    }
    return state
}

// TODO USER

export const actionReducers = {}
const actions = {
    fetchRegister,
    fetchLogin,
    fetchLogout,
    fetchExists,
    fetchAdminRegister,
    fetchAdminLogin,
    fetchAdminLogout,
    fetchAdminExists,
    clearActionState
}
export default actions
