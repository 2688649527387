import {ICategoryEntity} from "./types";
import {createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {entityFetchByIdAction, entityFetchManyByIdAction, entityFetchPageAction} from "../../Commons/actions";
import {getAdapterReducers} from "../../Commons/ReduxHelpers";
import CategoryModel from "../../../ApiModels/CategoryModel";
import {menusActions} from "../Menu/MenuRedux";

const initialData: EntityState<ICategoryEntity> = {
    ids: [],
    entities: {}
}

export const adapter = createEntityAdapter<ICategoryEntity>({
    // Assume IDs are stored in a field other than `book.id`
    selectId: (entity) => entity.id
})

const API_MODEL = new CategoryModel()
const ENTITY_NAME = 'category'

const fetchById = entityFetchByIdAction(ENTITY_NAME, API_MODEL)
const fetchManyById = entityFetchManyByIdAction(ENTITY_NAME, API_MODEL)
const fetchPage = entityFetchPageAction(ENTITY_NAME)

const entitiesSlice = createSlice({
    name: ENTITY_NAME,
    initialState: adapter.getInitialState(initialData),
    reducers: {
        ...getAdapterReducers(adapter)
    },
    extraReducers: {
        [fetchById.fulfilled.type]: (state, {payload}) => {
            state.entities[payload.id] = {
                id: payload.id,
                business_id: payload.business_id,
                name: payload.name,
                products_ids: payload.products,
                product_order: payload.product_order
            }
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
        [menusActions.fetchByIdComplete.fulfilled.type]: (state, {payload}) => {
            payload.categories.forEach((category: any) => {
                state.entities[category.id] = {
                    id: category.id,
                    business_id: category.business_id,
                    name: category.name,
                    products_ids: category.products_ids,
                    product_order: payload.product_order
                }
            })
            state.ids = Object.values(state.entities).flatMap<number, any>(x => x?.id ?? -1)
        },
    }
})

export const categoriesActions = {
    ...entitiesSlice.actions,
    fetchById,
    fetchManyById,
    fetchPage
}
export default entitiesSlice.reducer