import React, {Suspense, useEffect, useState} from 'react';
import './scss/App.scss';
import {StylesProvider} from '@material-ui/core';
import {BrowserRouter as Router} from "react-router-dom";
import LoadingContent from './components/LoadingContent'

const RootRouter = React.lazy(() => import('./routers/RootRouter'));

function App() {

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setFirstLoad(false);
        }, 1000);
    }, []);

    if (firstLoad) {
        return <LoadingContent/>
    }

    return (
        <StylesProvider injectFirst>
            <div className="App">
                <Router>
                    <Suspense fallback={<LoadingContent/>}>
                        <RootRouter/>
                    </Suspense>
                </Router>
            </div>
        </StylesProvider>
    );
}

export default App;
